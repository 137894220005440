<template>
    <main-template>
        <template v-slot:default="slotProps">
            <fetch-siren-root :linkName='linkName' :link="link" :logo="slotProps.logo">

            <div slot-scope="{ response, logo }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        <div class="md:flex justify-between w-full">
                            <div class="flex items-center text-secondary-lightblue text-xl font-bold">
                                <span class="cursor-pointer" @click="navigateToCategories(response)">
                                    Product Library
                                </span>
                                <svg class="mx-4" width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.125 1.95829L6.66667 7.49996L1.125 13.0416" stroke="#003E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span class="text-secondary-darkblue">
                                    Products
                                </span>
                            </div>
                        </div>

                    </page-header>
                    <product-list-paginated-list :response="response"></product-list-paginated-list>
                </template>
            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import AddProductForm from "@/v2/components/forms/AddProductForm.vue";
import Button from "@/v2/buttons/Button";
import FetchSirenRoot from "@/components/FetchSirenRoot";
import FormAction from "@/v2/components/FormAction";
import Loading from "@/components/Loading";
import MainTemplate from "@/v2/templates/MainTemplate";
import PageHeader from "@/v2/components/Header";
import ProductListPaginatedList from "@/v2/paginatedLists/ProductListPaginatedList.vue";
import ProductNavMenu from "@/v2/components/ProductNavMenu.vue";
import QASubmenuNav from "@/v2/components/QASubmenuNav.vue";
import UploadProductsForm from "@/components/CustomForms/UploadProductsForm.vue";
export default {
    name: "ProductLibrary",
    data() {
        return {
            loading: false
        }
    },
    components: {
        ProductNavMenu,
        QASubmenuNav,
        ProductListPaginatedList,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button,
        FormAction,
        UploadProductsForm,
        AddProductForm
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        navigateToClient(response) {
            let selfLink = response.body.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        navigateToCategories(response) {
            let selfLink = response.body.links.filter(link => link.rels.contains('product-categories')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
    }
}
</script>

<style scoped>

</style>
